/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getStakeholder = /* GraphQL */ `query GetStakeholder($id: ID!) {
  getStakeholder(id: $id) {
    id
    name
    category
    Reports {
      items {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStakeholderQueryVariables,
  APITypes.GetStakeholderQuery
>;
export const listStakeholders = /* GraphQL */ `query ListStakeholders(
  $filter: ModelStakeholderFilterInput
  $limit: Int
  $nextToken: String
) {
  listStakeholders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      category
      Reports {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStakeholdersQueryVariables,
  APITypes.ListStakeholdersQuery
>;
export const syncStakeholders = /* GraphQL */ `query SyncStakeholders(
  $filter: ModelStakeholderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncStakeholders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      category
      Reports {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncStakeholdersQueryVariables,
  APITypes.SyncStakeholdersQuery
>;
export const stakeholdersByUserID = /* GraphQL */ `query StakeholdersByUserID(
  $userID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelStakeholderFilterInput
  $limit: Int
  $nextToken: String
) {
  stakeholdersByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      category
      Reports {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StakeholdersByUserIDQueryVariables,
  APITypes.StakeholdersByUserIDQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    username
    email
    sprache
    lastLoginAt
    group
    privacyPolicyAccepted
    Partner {
      id
      name
      Mandantens {
        items {
          id
          name
          partnerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Klient {
      id
      name
      Report {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Mandantens {
      id
      name
      partnerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Stakeholder {
      items {
        id
        name
        category
        Reports {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userPartnerId
    userKlientId
    userMandantensId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      email
      sprache
      lastLoginAt
      group
      privacyPolicyAccepted
      Partner {
        id
        name
        Mandantens {
          items {
            id
            name
            partnerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Klient {
        id
        name
        Report {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Mandantens {
        id
        name
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Stakeholder {
        items {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPartnerId
      userKlientId
      userMandantensId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      username
      email
      sprache
      lastLoginAt
      group
      privacyPolicyAccepted
      Partner {
        id
        name
        Mandantens {
          items {
            id
            name
            partnerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Klient {
        id
        name
        Report {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Mandantens {
        id
        name
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Stakeholder {
        items {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPartnerId
      userKlientId
      userMandantensId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getPartner = /* GraphQL */ `query GetPartner($id: ID!) {
  getPartner(id: $id) {
    id
    name
    Mandantens {
      items {
        id
        name
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPartnerQueryVariables,
  APITypes.GetPartnerQuery
>;
export const listPartners = /* GraphQL */ `query ListPartners(
  $filter: ModelPartnerFilterInput
  $limit: Int
  $nextToken: String
) {
  listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      Mandantens {
        items {
          id
          name
          partnerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPartnersQueryVariables,
  APITypes.ListPartnersQuery
>;
export const syncPartners = /* GraphQL */ `query SyncPartners(
  $filter: ModelPartnerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPartners(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      Mandantens {
        items {
          id
          name
          partnerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPartnersQueryVariables,
  APITypes.SyncPartnersQuery
>;
export const getMandanten = /* GraphQL */ `query GetMandanten($id: ID!) {
  getMandanten(id: $id) {
    id
    name
    partnerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMandantenQueryVariables,
  APITypes.GetMandantenQuery
>;
export const listMandantens = /* GraphQL */ `query ListMandantens(
  $filter: ModelMandantenFilterInput
  $limit: Int
  $nextToken: String
) {
  listMandantens(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      partnerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMandantensQueryVariables,
  APITypes.ListMandantensQuery
>;
export const syncMandantens = /* GraphQL */ `query SyncMandantens(
  $filter: ModelMandantenFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMandantens(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      partnerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMandantensQueryVariables,
  APITypes.SyncMandantensQuery
>;
export const mandantensByPartnerID = /* GraphQL */ `query MandantensByPartnerID(
  $partnerID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMandantenFilterInput
  $limit: Int
  $nextToken: String
) {
  mandantensByPartnerID(
    partnerID: $partnerID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      partnerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MandantensByPartnerIDQueryVariables,
  APITypes.MandantensByPartnerIDQuery
>;
export const getKlient = /* GraphQL */ `query GetKlient($id: ID!) {
  getKlient(id: $id) {
    id
    name
    Report {
      items {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetKlientQueryVariables, APITypes.GetKlientQuery>;
export const listKlients = /* GraphQL */ `query ListKlients(
  $filter: ModelKlientFilterInput
  $limit: Int
  $nextToken: String
) {
  listKlients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      Report {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListKlientsQueryVariables,
  APITypes.ListKlientsQuery
>;
export const syncKlients = /* GraphQL */ `query SyncKlients(
  $filter: ModelKlientFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncKlients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      Report {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncKlientsQueryVariables,
  APITypes.SyncKlientsQuery
>;
export const getFragebogen = /* GraphQL */ `query GetFragebogen($id: ID!) {
  getFragebogen(id: $id) {
    id
    name
    fragen {
      items {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFragebogenQueryVariables,
  APITypes.GetFragebogenQuery
>;
export const listFragebogens = /* GraphQL */ `query ListFragebogens(
  $filter: ModelFragebogenFilterInput
  $limit: Int
  $nextToken: String
) {
  listFragebogens(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      fragen {
        items {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFragebogensQueryVariables,
  APITypes.ListFragebogensQuery
>;
export const syncFragebogens = /* GraphQL */ `query SyncFragebogens(
  $filter: ModelFragebogenFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFragebogens(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      fragen {
        items {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFragebogensQueryVariables,
  APITypes.SyncFragebogensQuery
>;
export const getFrage = /* GraphQL */ `query GetFrage($id: ID!) {
  getFrage(id: $id) {
    id
    fragebogen {
      id
      name
      fragen {
        items {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    frage
    skill {
      id
      name
      de
      en
      it
      fr
      kompetenz {
        id
        name
        de
        en
        it
        fr
        skills {
          items {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      kompetenzSkillsId
      __typename
    }
    translation {
      id
      de
      en
      it
      fr
      role
      frage {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      translationFrageId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fragebogenFragenId
    frageSkillId
    frageTranslationId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFrageQueryVariables, APITypes.GetFrageQuery>;
export const listFrages = /* GraphQL */ `query ListFrages(
  $filter: ModelFrageFilterInput
  $limit: Int
  $nextToken: String
) {
  listFrages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      frage
      skill {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      translation {
        id
        de
        en
        it
        fr
        role
        frage {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        translationFrageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenFragenId
      frageSkillId
      frageTranslationId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFragesQueryVariables,
  APITypes.ListFragesQuery
>;
export const syncFrages = /* GraphQL */ `query SyncFrages(
  $filter: ModelFrageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFrages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      frage
      skill {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      translation {
        id
        de
        en
        it
        fr
        role
        frage {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        translationFrageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenFragenId
      frageSkillId
      frageTranslationId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFragesQueryVariables,
  APITypes.SyncFragesQuery
>;
export const getKompetenz = /* GraphQL */ `query GetKompetenz($id: ID!) {
  getKompetenz(id: $id) {
    id
    name
    de
    en
    it
    fr
    skills {
      items {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKompetenzQueryVariables,
  APITypes.GetKompetenzQuery
>;
export const listKompetenzs = /* GraphQL */ `query ListKompetenzs(
  $filter: ModelKompetenzFilterInput
  $limit: Int
  $nextToken: String
) {
  listKompetenzs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      de
      en
      it
      fr
      skills {
        items {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListKompetenzsQueryVariables,
  APITypes.ListKompetenzsQuery
>;
export const syncKompetenzs = /* GraphQL */ `query SyncKompetenzs(
  $filter: ModelKompetenzFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncKompetenzs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      de
      en
      it
      fr
      skills {
        items {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncKompetenzsQueryVariables,
  APITypes.SyncKompetenzsQuery
>;
export const getSkill = /* GraphQL */ `query GetSkill($id: ID!) {
  getSkill(id: $id) {
    id
    name
    de
    en
    it
    fr
    kompetenz {
      id
      name
      de
      en
      it
      fr
      skills {
        items {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    kompetenzSkillsId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSkillQueryVariables, APITypes.GetSkillQuery>;
export const listSkills = /* GraphQL */ `query ListSkills(
  $filter: ModelSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      de
      en
      it
      fr
      kompetenz {
        id
        name
        de
        en
        it
        fr
        skills {
          items {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      kompetenzSkillsId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillsQueryVariables,
  APITypes.ListSkillsQuery
>;
export const syncSkills = /* GraphQL */ `query SyncSkills(
  $filter: ModelSkillFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSkills(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      de
      en
      it
      fr
      kompetenz {
        id
        name
        de
        en
        it
        fr
        skills {
          items {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      kompetenzSkillsId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSkillsQueryVariables,
  APITypes.SyncSkillsQuery
>;
export const getTranslation = /* GraphQL */ `query GetTranslation($id: ID!) {
  getTranslation(id: $id) {
    id
    de
    en
    it
    fr
    role
    frage {
      id
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      frage
      skill {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      translation {
        id
        de
        en
        it
        fr
        role
        frage {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        translationFrageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenFragenId
      frageSkillId
      frageTranslationId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    translationFrageId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTranslationQueryVariables,
  APITypes.GetTranslationQuery
>;
export const listTranslations = /* GraphQL */ `query ListTranslations(
  $filter: ModelTranslationFilterInput
  $limit: Int
  $nextToken: String
) {
  listTranslations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      de
      en
      it
      fr
      role
      frage {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      translationFrageId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTranslationsQueryVariables,
  APITypes.ListTranslationsQuery
>;
export const syncTranslations = /* GraphQL */ `query SyncTranslations(
  $filter: ModelTranslationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTranslations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      de
      en
      it
      fr
      role
      frage {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      translationFrageId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTranslationsQueryVariables,
  APITypes.SyncTranslationsQuery
>;
export const getReport = /* GraphQL */ `query GetReport($id: ID!) {
  getReport(id: $id) {
    id
    name
    fragebogen {
      id
      name
      fragen {
        items {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    stakeholderKategorie
    selfassesment
    Klient {
      id
      name
      Report {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    Stakeholder {
      id
      name
      category
      Reports {
        items {
          id
          name
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          stakeholderKategorie
          selfassesment
          Klient {
            id
            name
            Report {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Stakeholder {
            id
            name
            category
            Reports {
              items {
                id
                name
                status
                stakeholderKategorie
                selfassesment
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                stakeholderReportsId
                klientReportId
                reportFragebogenId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          stakeholderReportsId
          klientReportId
          reportFragebogenId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    stakeholderReportsId
    klientReportId
    reportFragebogenId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReportQueryVariables, APITypes.GetReportQuery>;
export const listReports = /* GraphQL */ `query ListReports(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      stakeholderKategorie
      selfassesment
      Klient {
        id
        name
        Report {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Stakeholder {
        id
        name
        category
        Reports {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      stakeholderReportsId
      klientReportId
      reportFragebogenId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportsQueryVariables,
  APITypes.ListReportsQuery
>;
export const syncReports = /* GraphQL */ `query SyncReports(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncReports(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      stakeholderKategorie
      selfassesment
      Klient {
        id
        name
        Report {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Stakeholder {
        id
        name
        category
        Reports {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      stakeholderReportsId
      klientReportId
      reportFragebogenId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncReportsQueryVariables,
  APITypes.SyncReportsQuery
>;
export const getFragebogenErgebnis = /* GraphQL */ `query GetFragebogenErgebnis($id: ID!) {
  getFragebogenErgebnis(id: $id) {
    id
    frage {
      id
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      frage
      skill {
        id
        name
        de
        en
        it
        fr
        kompetenz {
          id
          name
          de
          en
          it
          fr
          skills {
            items {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        kompetenzSkillsId
        __typename
      }
      translation {
        id
        de
        en
        it
        fr
        role
        frage {
          id
          fragebogen {
            id
            name
            fragen {
              items {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          frage
          skill {
            id
            name
            de
            en
            it
            fr
            kompetenz {
              id
              name
              de
              en
              it
              fr
              skills {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            kompetenzSkillsId
            __typename
          }
          translation {
            id
            de
            en
            it
            fr
            role
            frage {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            translationFrageId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          fragebogenFragenId
          frageSkillId
          frageTranslationId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        translationFrageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenFragenId
      frageSkillId
      frageTranslationId
      __typename
    }
    report {
      id
      name
      fragebogen {
        id
        name
        fragen {
          items {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      stakeholderKategorie
      selfassesment
      Klient {
        id
        name
        Report {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Stakeholder {
        id
        name
        category
        Reports {
          items {
            id
            name
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            status
            stakeholderKategorie
            selfassesment
            Klient {
              id
              name
              Report {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Stakeholder {
              id
              name
              category
              Reports {
                nextToken
                startedAt
                __typename
              }
              userID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            stakeholderReportsId
            klientReportId
            reportFragebogenId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      stakeholderReportsId
      klientReportId
      reportFragebogenId
      __typename
    }
    reportId
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fragebogenErgebnisFrageId
    fragebogenErgebnisReportId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFragebogenErgebnisQueryVariables,
  APITypes.GetFragebogenErgebnisQuery
>;
export const listFragebogenErgebnis = /* GraphQL */ `query ListFragebogenErgebnis(
  $filter: ModelFragebogenErgebnisFilterInput
  $limit: Int
  $nextToken: String
) {
  listFragebogenErgebnis(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      frage {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      report {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      reportId
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenErgebnisFrageId
      fragebogenErgebnisReportId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFragebogenErgebnisQueryVariables,
  APITypes.ListFragebogenErgebnisQuery
>;
export const syncFragebogenErgebnis = /* GraphQL */ `query SyncFragebogenErgebnis(
  $filter: ModelFragebogenErgebnisFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFragebogenErgebnis(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      frage {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      report {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      reportId
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenErgebnisFrageId
      fragebogenErgebnisReportId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFragebogenErgebnisQueryVariables,
  APITypes.SyncFragebogenErgebnisQuery
>;
export const fragebogenErgebnisByReportIdAndId = /* GraphQL */ `query FragebogenErgebnisByReportIdAndId(
  $reportId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFragebogenErgebnisFilterInput
  $limit: Int
  $nextToken: String
) {
  fragebogenErgebnisByReportIdAndId(
    reportId: $reportId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      frage {
        id
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        frage
        skill {
          id
          name
          de
          en
          it
          fr
          kompetenz {
            id
            name
            de
            en
            it
            fr
            skills {
              items {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          kompetenzSkillsId
          __typename
        }
        translation {
          id
          de
          en
          it
          fr
          role
          frage {
            id
            fragebogen {
              id
              name
              fragen {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            frage
            skill {
              id
              name
              de
              en
              it
              fr
              kompetenz {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              kompetenzSkillsId
              __typename
            }
            translation {
              id
              de
              en
              it
              fr
              role
              frage {
                id
                frage
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                fragebogenFragenId
                frageSkillId
                frageTranslationId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              translationFrageId
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            fragebogenFragenId
            frageSkillId
            frageTranslationId
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          translationFrageId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        fragebogenFragenId
        frageSkillId
        frageTranslationId
        __typename
      }
      report {
        id
        name
        fragebogen {
          id
          name
          fragen {
            items {
              id
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              frage
              skill {
                id
                name
                de
                en
                it
                fr
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                kompetenzSkillsId
                __typename
              }
              translation {
                id
                de
                en
                it
                fr
                role
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                translationFrageId
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              fragebogenFragenId
              frageSkillId
              frageTranslationId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        stakeholderKategorie
        selfassesment
        Klient {
          id
          name
          Report {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Stakeholder {
          id
          name
          category
          Reports {
            items {
              id
              name
              fragebogen {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              status
              stakeholderKategorie
              selfassesment
              Klient {
                id
                name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Stakeholder {
                id
                name
                category
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              stakeholderReportsId
              klientReportId
              reportFragebogenId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        stakeholderReportsId
        klientReportId
        reportFragebogenId
        __typename
      }
      reportId
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fragebogenErgebnisFrageId
      fragebogenErgebnisReportId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FragebogenErgebnisByReportIdAndIdQueryVariables,
  APITypes.FragebogenErgebnisByReportIdAndIdQuery
>;
