/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "authAPI",
            "endpoint": "https://32rzfi59hb.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "mailAPI",
            "endpoint": "https://dfs8uu46v4.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://myceoytktvb43llibi55atyurq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kfgh7upgfvb25hewv2ach5vtga",
    "aws_cognito_identity_pool_id": "eu-west-1:abde2f91-83b1-4df3-9edc-8ba999cb7e6b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_aaa4ScAtq",
    "aws_user_pools_web_client_id": "2kml5ifpplkdqqt8glrjvi42hc",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
