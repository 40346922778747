import { defineStore } from 'pinia'
import { Auth } from 'aws-amplify'

export const useAuthStore = defineStore('AuthStore', () => {
  const user = ref<object | null>(null)

  function setUser(myUser: object) {
    user.value = myUser
  }
  async function logout() {
    await Auth.signOut()
    user.value = null
    return
  }
  return { user, setUser, logout }
})
