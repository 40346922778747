import { Auth, API } from 'aws-amplify'
import * as queries from '@@/src/graphql/queries'
import { useAuthStore } from '~/stores/auth'

/*
Runs first in to protect Admin routes from non-admin users
*/

export default defineNuxtRouteMiddleware(async () => {
  const authStore = useAuthStore()
  const { setUser, user } = authStore
  if (user) {
    return
  }

  try {
    const cognito_user = await Auth.currentAuthenticatedUser()
    const { data } = await API.graphql({
      query: queries.getUser,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: { id: cognito_user.attributes.sub }
    })
    const database_user = data.getUser
    setUser(database_user)
  } catch {
    setUser(null)
  }
})
