import { defineNuxtPlugin } from '#app'
import { Amplify, Auth } from 'aws-amplify'
import awsExport from '@@/src/aws-exports.js' // gets updated after running `amplify pull`

export default defineNuxtPlugin(() => {
  Amplify.configure(awsExport)

  return {
    provide: {
      auth: Auth // provide Amplify Auth instance to Nuxt app
      /* const { $auth } = useNuxtApp() können wir auf alles zugreifen
        https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/#option-2-call-authentication-apis-manually
      */
    }
  }
})
