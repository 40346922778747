export default defineNuxtRouteMiddleware(() => {
  const showModal = useState('showCookieModal', () => false)

  if (import.meta.client) {
    const gdprAccepted = useCookie('gdprAccepted')
    if (!gdprAccepted.value) {
      showModal.value = true
    }
  }
  return
})
