import { API } from 'aws-amplify'
import type { GraphQLQuery } from '@aws-amplify/api'
import * as queries from '@@/src/graphql/queries'
import type { ListFragebogensQuery } from '@@/src/API'

class FragebogenRepository {
  getFragebogen() {
    return API.graphql<GraphQLQuery<ListFragebogensQuery>>({
      query: queries.listFragebogens
    })
  }
}

export default FragebogenRepository
